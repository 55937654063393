html {
    height: 100%;
}

body {
    background: black;
    color: white;
    text-align: center;
    font-family: "Oswald", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-transform: uppercase;
}

#root {
    padding: 20px;
}

a {
    color: white;
}

h1 {
    font-size: 48px;
    line-height: 1;
    margin-top: 0;
}

h2 {
    font-size: 26px;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 20px;
}

.background {
    position: fixed;
    background-image: url("./background.jpg");
    background-size: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    opacity: 0.2;
    background-position: center;
}

.image {
    width: 200px;
    display: inline-block;
}

.image img {
    max-width: 100%;
}

.whodis {
    position: fixed;
    left: 0;
    bottom: 20px;
    width: 100%;
    font-size: 12px;
    text-align: center;
}

@media (min-width: 768px) {
    .whodis {
        left: auto;
        right: 20px;
        text-align: right;
    }
}

.whodis {
    opacity: 0.3;
}

button {
    outline: none;
    border: none;
    background: red;
    color: white;
    padding: 10px 20px;
    font-family: "Anonymous Pro", monospace;
    font-size: 20px;
    cursor: pointer;
    margin-top: 10px;
}

button + button {
    margin-left: 10px;
}

.fullscreen-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -100;
}

.fullscreen-bg__video {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
}

@media (min-aspect-ratio: 16/9) {
    .fullscreen-bg__video {
        width: 100%;
        height: auto;
    }
}

@media (max-aspect-ratio: 16/9) {
    .fullscreen-bg__video {
        width: auto;
        height: 100%;
    }
}
